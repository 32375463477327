import React from "react"
import { Link as ReachLink, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Heading, Link } from "@chakra-ui/core"

const TermsConditionsPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulPage(unique: {eq: "TermsConditionsPage"}) {
        title
        description
        tags
        h1
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={data.contentfulPage.title} description={data.contentfulPage.description} keywords={data.contentfulPage.tags} />
      <Box>
        <Heading as="h1" mt={6} textAlign="center" lineHeight="2">{data.contentfulPage.h1}</Heading>
        <Box mt={2} id="index" color="gray.600" dangerouslySetInnerHTML={{ __html: data.contentfulPage.body.childMarkdownRemark.html }}></Box>
      </Box>
    </Layout>
  )
}

export default TermsConditionsPage
